import React from 'react'
import flagUnaitedState from "../../../assets/images/turkeyadd 3.png";
import {useTranslation} from 'react-i18next';


function ContentAddress({user, branch , sticker}) {
    const {t} = useTranslation();

        // Function to download image
        const downloadImage = async () => {
            try {
                console.log("sti url is:" +sticker);
                
                const response = await fetch(sticker);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = "sticker.png"; // Set the filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading image:", error);
            }
        };


    return (
        <div className="cards-bg-wites" style={{borderTopLeftRadius: "140px" ,boxShadow: '0px 0px 76px 0px rgba(0, 0, 0, 0.07)'}}>
            <div className="bule-cards" dir={"ltr"}>
                <img src={flagUnaitedState} alt="flag usa" loading="lazy"/>
                <div className="row w-100">

                    <div className="col-12 col-md-4 ml-auto text-right text-md-left address-header">
                        <div className="cards-bule-content">
                            <h5>{t("suiteNumber")}</h5>
                            <h4>{user.suite}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-tables-flex-new" style={{padding: "0 1.6rem"}}>
                <div className="address-content mx-auto">
                  
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="tables-content">
                                <p>
                                    {t("name")}
                                    <br/>
                                    <h4>{user.name}</h4>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="tables-content">
                                <p>
                                    {t("address")}
                                    <br/>
                                    <h4>{branch.address}</h4>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="tables-content">
                                <p>
                                    {t("city")}
                                    <br/>
                                    <h4>{branch.city}</h4>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-12 col-md-4">
                            <div className="tables-content">
                                <p>
                                    {branch.code === 'tur'? t("District"): t("state")}
                                    <br/>
                                    <h4>{branch.state}</h4>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="tables-content">
                                <p>
                                    {t("zip")}
                                    <br/>
                                    <h4>{branch.zip}</h4>
                                </p>
                            </div>
                        </div>
                        {branch.code === 'tur'&&
                        <div className="col-12 col-md-4">
                            <div className="tables-content">
                                <p>
                                    {t("Mahalle")}
                                    <br/>
                                    <h4>{branch.mahalle}</h4>
                                </p>
                            </div>
                        </div>
                        }

                        <div className="col-12 col-md-4">
                            <div className="tables-content">
                                <p>
                                    {t("phoneNum")}
                                    <br/>
                                    <h4>{branch.phone}</h4>
                                </p>
                            </div>
                        </div>
                    </div>
             
                             {/* Download Image Button */}
                             {sticker &&(
                                <div className=" text-center mx-auto mt-3">
                                    <div className="text-center pb-3 text-center ">
                                        <a href={sticker} target='_blank' className=" text-center text-center text-muted  ">
                                            {t("Download Sticker")}
                                        </a>
                                    </div>
                                </div>
                                )}

                </div>
            </div>
        </div>
    )
} 

export default ContentAddress
 